var swiper;
var swiperInner;
var modal = {
    $trigger : $(".js__modal-trigger"),
    $content : $(".c-modal"),
    $inner : $(".c-modal__container"),
    $close : $(".js__modal-close"),
    $overlay : $(".c-modal__overlay"),
    target : 0,
    height : 0,
    position: 0,

    type : '',
    init : function () {

        setTimeout(function(){
            TweenMax.set(modal.$content, {display:'none'});
            TweenMax.set(modal.$overlay, {display:'block'});
        },1e3);

        modal.$trigger.on("click",function(){
            modal.target = $(this).data("index");
            modal.open();
            return false;
        });
        modal.$close.on("click",function(){
            modal.close();
        });

    },
    open : function(){
        TweenMax.set(modal.$inner, {
            x:'-50px'
        });
        if(modal.target !== undefined){
            swiper.slideTo(modal.target);
        }
        TweenMax.to(modal.$content, 0.18, {
            display: 'block',
            opacity: 1,
            ease: Power2.easeOut,
            onComplete : function(){
                $('#l-header__fixed').css({'display':'none'});
                modal.height = $(modal.target).find(".c-modal__container").outerHeight();
                //modal.position = $(document).scrollTop() + [$(window).height() - modal.$inner.height()] / 2;
                modal.position = $(document).scrollTop() + 30;
                TweenMax.set(modal.$inner, {top:modal.position});
                TweenMax.set($(".c-modal__target"), { opacity:1 });
                TweenMax.to(modal.$inner, 0.68, {
                    opacity: 1,
                    x:'0px',
                    ease: Back.easeOut.config(1.7)
                });
            }
        });
    },
    close : function(){
        TweenMax.to(modal.$inner, 0.48, {
            opacity: 0,
            x:'-50px',
            ease: Power2.easeOut,
            onComplete : function(){
                TweenMax.to(modal.$content, 0.18, {
                    display: 'none',
                    opacity: 0,
                    ease: Power2.easeOut,
                });
                $('#l-header__fixed').removeAttr('style');
                TweenMax.set($(".c-modal__target"), { opacity:0 });
            }
        });
    },
    resize : function(){
        let mH = 0;
        for (let i = 0; i < $('.c-modal__target').length; i++) {
            if ($('.c-modal__target').eq(i).height() > mH) {
                mH = $('.c-modal__target').eq(i).height();
            }
        }
        $('.c-modal__target').height(mH);
    }
}


$(window).on("load",function () {
    if($(".swiper-container")[0]){
        swiper = new Swiper('.swiper-container',{
            autoHeight: false, //enable auto height
            calculateHeight:false,
            spaceBetween: 10,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        swiperInner = new Swiper('.swiper-container__inner',{
            autoHeight: true, //enable auto height
            spaceBetween: 10,
            nested: true,
            pagination: {
                el: '.swiper-pagination__inner',
                clickable: true,
            },
        });
    }
    if($(".c-modal")[0]) modal.init(); TweenMax.set($(".c-modal__target iframe"), { display:'none' }); modal.resize();
});

var timer = false;
$(window).on('resize',function() {
    if (timer !== false) {
        clearTimeout(timer);
    }
    timer = setTimeout(function() {
        if($(".c-modal")[0]) modal.resize();
    }, 200);
});

//print
var print_target;
$('.js__print').click(function(){
    print_target = $(this).parents(".c-modal__target");
    $(".c-modal__target").removeClass("print");
    print_target.addClass('print');
    //印刷
    window.print();
    return false;
});