//============================================================

// -- map

//============================================================

var map = {

    init:function(){

        var kamio = {
                lat : '35.979242',
                lng : '139.599635'
            },
            hannnou = {
                lat : '35.857815',
                lng : '139.348435'
            },
            sakado = {
                lat : '35.967535',
                lng : '139.390577'
            },
            higashiyamato = {
                lat : '35.742778',
                lng : '139.434812'
            };
        var latlng,
            map;

        for (var i = 0; i < 4; i++) {
            switch (i) {
                case 0:
                    latlng = new google.maps.LatLng(kamio.lat, kamio.lng);
                    break;
                case 1:
                    latlng = new google.maps.LatLng(hannnou.lat, hannnou.lng);
                    break;
                case 2:
                    latlng = new google.maps.LatLng(sakado.lat, sakado.lng);
                    break;
                case 3:
                    latlng = new google.maps.LatLng(higashiyamato.lat, higashiyamato.lng);
                    break;
            }
            var myOptions = {
                zoom: 17, /*拡大比率*/
                center: latlng/*表示枠内の中心点*/
            };
            switch (i) {
                case 0:
                    map = new google.maps.Map(document.getElementById('kamio_map'), myOptions);
                    break;
                case 1:
                    map = new google.maps.Map(document.getElementById('hannnou_map'), myOptions);
                    break;
                case 2:
                    map = new google.maps.Map(document.getElementById('sakado_map'), myOptions);
                    break;
                case 3:
                    map = new google.maps.Map(document.getElementById('higashiyamato_map'), myOptions);
                    break;
            }
            /*アイコン設定▼*/
            var icon = new google.maps.MarkerImage('assets/img/common/icon-pin.svg',
                new google.maps.Size(32, 48), /*アイコンサイズ設定*/
                new google.maps.Point(0, 0)/*アイコン位置設定*/
            );
            var markerOptions = {
                position: latlng,
                map: map,
                icon: icon
            };
            var marker = new google.maps.Marker(markerOptions);
        }
    }

};

$(window).on("load",function () {
    if($(".c-modal__map")[0]) map.init();
});