import smoothScrollManager from './smoothScrollManager';

let smooth = new smoothScrollManager();


var scroll = {
    $trigger : $(".js__scroll-position"),
    $target : $(".js__scroll-target,.js__scroll-title,.js__scroll-text,.js__scroll-object"),
    position: [],
    w: 0,
    h: 0,
    bodyH: 0,
    scr : 0,

    init : function () {

        for (let i = 0; i < $(".js__scroll-title").length; i++){
            let en = new SplitText($(".js__scroll-title").eq(i).find(".c-font__en"), {type: "words,chars"});
            let jp = new SplitText($(".js__scroll-title").eq(i).find(".c-font__jp"), {type: "words,chars"});
        }

    },

    resize : function () {

        scroll.position = [];

        for (let i = 0; i < scroll.$trigger.length; i++) {
            scroll.position[i] = scroll.$trigger.eq(i).offset().top;
        }

        if(scroll.w < 1300){
            $('#page-top.pc').removeClass('d-show');
        }else{
            $('#page-top.pc').addClass('d-show');
        }

    },

    motion : function(){

        for (let i = 0; i <  scroll.position.length; i++) {
            if( scroll.position[i] <= scroll.scr + (scroll.h * 0.75)) {
                scroll.$trigger.eq(i).find(scroll.$target).addClass("is-done");
            }
        }
        if(scroll.scr > scroll.h/2 && scroll.w > 1300){
            $('#page-top.pc').addClass('d-show');
            if(scroll.scr > scroll.bodyH){
                $('#page-top.pc').addClass('bottom');
            }else{
                $('#page-top.pc').removeClass('bottom');
            }
        }else{
            $('#page-top.pc').removeClass('d-show');
        }

    }
}


$(window).on("load",function () {

    scroll.w = $(window).width();
    scroll.bodyH = $('#l-body').height();

    if($(".js__scroll-position")[0]) scroll.init();
    if($(".js__scroll-position")[0]) scroll.resize();

    render();
    let inc = window.pageYOffset || document.documentElement.scrollTop;
    let hash = location.hash;
    if(hash !== '' && hash !== null && hash !== undefined){
        let position = $(hash).find('.js__scroll-title').offset().top - $('#l-header').height() - 20;
        setTimeout(function() {
            smooth.resize();
            smooth.updateScroll(inc, "");
            $('html,body').animate({scrollTop: position}, '1',function(){
                smooth.mode = 'scroll';
            });
        },100);
    }else{
        setTimeout(function() {
            smooth.resize();
            smooth.updateScroll(inc, "");
            smooth.mode = 'scroll';
        },100);
    }
});
$(window).on("resize",function () {
    scroll.w = $(window).width();
    if($(".js__scroll-position")[0]) scroll.resize();
    smooth.resize();
});
$(window).on("load scroll",function () {
    scroll.h = $(window).height();
    scroll.scr = $(window).scrollTop();
    if($(".js__scroll-position")[0]) scroll.motion();
});

$(window).on("scroll",function () {
    let inc = window.pageYOffset || document.documentElement.scrollTop;
    if(smooth.mode === 'scroll') smooth.updateScroll(inc,"");
});

let render  = function () {
    if(!requestAnimationFrame){
        setTimeout(function () {
            render();
        },1000/60);
    }else{
        requestAnimationFrame(render);
    }

    smooth.move();

}

