export default class smoothScrollManager{
    constructor(inc){

        this.w = $(window).innerWidth();
        this.h = $(window).innerHeight();

        this.$container = $("#l-scrollbody");
        this.$body = $("#l-scrollbody__inner");
        this.$footer =  $("#l-footer");
        this.$prarax = $('.js__psc');

        this.$posi = $(".js__sc_posi");

        this.$pagetop = $("#js__pagetop");

        this.dir = "next";


        this.inc = 0;
        this.incX = 0; // [ 横スクロール用 ]
        this.nowInc = 0;
        this.prevInc = 0;

        this.paraInc = 0;
        this.paraPoint = [];

        this.scPoint   = [];
            // height = page.$f.offset().top + page.$f.innerHeight() - h; // scroll limit
        this.height = 0; // scroll limit

        this.timer = [];

        this.type = "normal";


        this.pageID;

        this.mode = "landing"; //[ 読み込みのモード ]


    }
    updateScroll(inc,dir){
        this.inc = inc;
        this.dir = (dir==="")?(this.inc>this.prevInc)?"next":"prev":dir;
        this.prevInc = this.inc;
        this.mode = "scroll";

    }
    init(){


        // this.footerPosi = this.$footer.offset().top;

        //$('html,body').animate({ scrollTop: 0 }, '1');

        // this.move();
        // this.resize();
        // setTimeout(function () {
        //     resize(;
        // },1e2)

    }
    move() {


        let add = (Useragnt.pc)?0.08:1;

        this.nowInc += add * (this.inc - this.nowInc);
        this.paraInc += add * (this.inc - this.paraInc)/100;

        this.trans(this.$body, this.nowInc);


    }
    trans($t,inc) {

        if(Useragnt.pc){

            TweenMax.set($t,{
                y:-inc,
                x:-this.incX,
            });

            // $t.css({
            //     "transform" : "translateY(" + -inc + "px) translateZ(0)"
            // });
        }
    }

    resize () {
        this.w = $(window).innerWidth();
        this.h = $(window).innerHeight();

        this.inc = window.pageYOffset || document.documentElement.scrollTop;

        this.height = this.$body.innerHeight() - window.innerHeight;

        if(!$('body#top')[0] && this.inc === 0){
            $("body").css({"height":this.$body.innerHeight() + $('#l-header').height()});
        }else{
            $("body").css({"height":this.$body.innerHeight()});
        }
    }
}
