//============================================================

// -- kv

//============================================================

var _loadFlg = false,
    _timeFlg,
    _timer,
    _screenWidth,
    _screenHeight,
    _scr = 0,

    $target = '',

    _t_width,
    _t_height,
    _t_classN,
    _offsetX,
    _offsetY,
    i,

    _moveAnimeA,
    _moveAnimeB;

var kv = {

    coverL : $(".l-anime__grid-cover"),
    coverR : $(".l-anime__grid-reverse-cover"),

    init : function () {

        let min = 1,
            max = 3,
            i = 0,
            j = 0,
            random = [],
            _text = '',
            _chars = '';

        if(!$(".l-anime__grid-cover")[0]) return false;

        //header
        if($("#top")[0]){
            TweenMax.set($("#l-header__fixed"), {
                y:'-90px'
            });
        }

        //text
        for (i = 0; i < $(".js__font-anime").length; i++){
                _text = new SplitText($(".js__font-anime").eq(i), {type: "words,chars"}),
                _chars = _text.chars;
            TweenMax.set(_chars, {
                rotation:-90,
                transformOrigin:"right bottom"
            });
        }
        for (i = 0; i < $(".js__hide-y-anime").length; i++){
            TweenMax.set($(".js__hide-y-anime .target"), {
                y:'100%'
            });
        }

        //canvas
        // 変数定義
        let cs       = document.getElementById('l-anime__grid'),
            ctx      = cs.getContext('2d');

        // 線の基本スタイル
        ctx.strokeStyle = '#f2e6e5';
        ctx.lineWidth = 1;

        let drawLine = function() {
            ctx.beginPath();
            for( i=1; i<30; i++ ){
                ctx.moveTo(0, i*220);
                ctx.lineTo(i*290, 0);
            }
            ctx.closePath();
            ctx.stroke();
        };

        let csR       = document.getElementById('l-anime__grid-reverse'),
            ctxR      = csR.getContext('2d');

        // 線の基本スタイル
        ctxR.strokeStyle = '#f2e6e5';
        ctxR.lineWidth = 1;

        let drawLineR = function() {
            ctxR.beginPath();
            for( i=1; i<30; i++ ){
                ctxR.moveTo(0, i*220);
                ctxR.lineTo(i*290, 0);
            }
            ctxR.closePath();
            ctxR.stroke();
        };

        // addClass
        for (i = 0; i < 35; i++) {
            random[i] = Math.floor( Math.random() * (max + 1 - min) ) + min;
        }
        for (i = 0; i < $(".object-clone").length; i++) {
            for (j = 0; j < 35; j++) {
                $(".object-clone").eq(i).find(".object").eq(j).addClass("num" + random[j]);
            }
        }

        kv.animation();
        if(_screenWidth < 750) return false;
        // drawLine();
        // drawLineR();

    },

    animation : function () {

        let _delayA = 0,
            _delayB = 0,
            _delayC = 0,
            easing = SlowMo.ease.config(0.7, 0.7, false);

        let drawAnime = function() {

            if(_screenWidth < 750){
                _delayA = 0;
                _delayB = 500;
                _delayC = 1600;
            }else{
                let hash = location.hash;
                if(hash !== '' && hash !== null && hash !== undefined){
                    _delayA = 3e3;
                }else{
                    _delayA = 600;
                }
                _delayB = 1150;
                _delayC = 1800;
                //Line
                // TweenMax.to(kv.coverL, 6.18, {
                //     delay: 1.68,
                //     x: '0px',
                //     height: '2000px',
                //     ease: Sine.easeOut
                // });
                // TweenMax.to(kv.coverR, 6.18, {
                //     x: '0px',
                //     width: '3000px',
                //     ease: Sine.easeOut
                // });
            }

            //最初の丸のモーション
            setTimeout(function(){
                $("#l-kv").addClass("load");
                TweenMax.to("#circle-start", 1.28, {
                    morphSVG:"#circle-end",
                    onComplete:function(){
                        if(_screenWidth > 750){
                            //handleEffectParent();
                            popupAnime(); //❏❏❏❏❏
                        }
                        TweenMax.to($('#l-kv'), 1, {delay:0.48,className:"+=is-done"});
                        TweenMax.to($("#l-kv__mask"), .68, {
                            //delay: .8,
                            display:'none',
                            ease: easing,
                            onComplete:function(){
                                setTimeout(function(){
                                    motion.text($('#l-kv__text').find('.js__font-anime'));
                                    setTimeout(function(){
                                        motion.hideY($('#l-kv__text').find('.js__hide-y-anime'));
                                        TweenMax.to($("#l-header__fixed"), .78, {
                                            y: '0px',
                                            ease: Circ.easeOut,
                                            onComplete:function(){
                                                $("#l-header__fixed").removeAttr('style');
                                            }
                                        });
                                    },_delayC);
                                },_delayB);
                            }
                        });
                    }
                });
            },_delayA);

        }
        drawAnime();

        //❏❏❏❏❏
        let popupAnime = function() {

            let easing = Elastic.easeOut.config(1, 0.35),
                i = 0,
                _num = 0,
                _length = $("#l-kv.l-anime .object-line").length;

            // for (i = 0; i < _length; i++) {
            //
            //     _num = i + 1;
            //
            //     TweenMax.to($("#l-kv.l-anime .l-anime__object .object.num"+_num+">*"), 1.88, {
            //         delay:i*2.3/10,
            //         y: '0px',
            //         scale: 1,
            //         display:'block',
            //         ease: easing
            //     });
            //
            // }

            // TweenMax.set($("#l-kv.l-anime").find(".object-matome"), {
            //     delay:.05,
            //     autoAlpha: 1,
            //     display:'block',
            // });

            TweenMax.to($("#l-kv.l-anime").find(".object-matome"), 1.88, {
                //scale: 1,
                opacity:1,
                ease: Circ.easeOut,
                onStart:function(){
                    $("#l-kv .l-anime__object .object").children().addClass("is-done").removeAttr("style");
                }
            });

            setTimeout(function(){
                _loadFlg = true;
                _timeFlg = true;
                $("#l-kv .l-anime__object .object").addClass("move");
                //_timer = setInterval(moveAnime, 1000 / 60); //☆☆☆☆☆
                moveAnime(); //☆☆☆☆☆
                barabaraAnime();//△△△△△

                if(_screenWidth > 750){
                    _timer = setInterval(function(){
                        barabaraAnime();//△△△△△
                    },6e3);
                }
            },4e3);

        }

    }

}

//============================================================

// -- motion

//============================================================

var motion = {

    text:function(target){

        TweenMax.staggerTo(target.children().children(), 0.23,
            {
                rotation:0,
                transformOrigin:"right bottom",
                ease: Circ.easeOut
            },
            0.09
        );
    },

    hideY:function(target){

        TweenMax.to(target.find(".target"), 0.86,
            {
                y:'0%',
                ease: Circ.easeOut
            }
        );
    }

};

//============================================================

// -- move

//============================================================

let moveAnime = function() {

    // let _values,
    //     _matrix,
    //     _props;
    //
    // function transform_value(e){
    //     _values = e.split('(')[1];
    //     _values = _values.split(')')[0];
    //     _values = _values.split(',');
    //     _matrix = {
    //         'transform-x':_values[4]
    //     };
    //     return _matrix;
    // }
    let _targetA = $("#l-kv .object-line"),
        _targetB = $("#l-kv .object-matome__1"),
        _targetC = $("#l-kv .object-matome__2");
        // _targetD = $(".l-about .l-anime .object-all"),
        // _targetE = $(".l-recruit .l-anime .object-all");

    if(_screenWidth > 750){

        _moveAnimeA = TweenMax.fromTo(_targetA, 76, {
            x: '0px',
            y: '0px',
        },{
            x: '-1564px',
            y: '1185px',
            repeat: -1,
            ease: Power0.easeNone,
            onComplete:function(){
                handleEffect();
                echo.init({
                    offset:500
                });
            }
        });

        _moveAnimeB = TweenMax.fromTo([_targetB,_targetC], 76, {
            x: '0px',
            y: '0px',
        },{
            x: '-1646px',
            y: '1152px',
            repeat: -1,
            ease: Power0.easeNone
        });

        // _moveAnimeB = TweenMax.fromTo(_targetB, 6, {
        //     x: '0px',
        //     y: '0px',
        // },{
        //     x: '-1646px',
        //     y: '1152px',
        //     repeat: -1,
        //     ease: Power0.easeNone
        // });
        //
        // _moveAnimeC = TweenMax.fromTo(_targetC, 6, {
        //     x: '0px',
        //     y: '0px',
        // },{
        //     x: '-1646px',
        //     y: '1152px',
        //     repeat: -1,
        //     ease: Power0.easeNone
        // });

        // _moveAnimeD = TweenMax.fromTo(_targetD, 136, {
        //     x: '0px',
        //     y: '0px',
        // },{
        //     x: '-1556px',
        //     y: '1090px',
        //     repeat: -1,
        //     ease: Power0.easeNone
        // });
        //
        // _moveAnimeE = TweenMax.fromTo(_targetE, 136, {
        //     x: '0px',
        //     y: '0px',
        // },{
        //     x: '-1556px',
        //     y: '1090px',
        //     repeat: -1,
        //     ease: Power0.easeNone
        // });

    }

    setInterval(function(){
        if(_screenWidth > 750 && _scr < _screenHeight / 1.2){
            handleEffect();
            echo.init({
                offset:500
            });
        }
    },6e3);

}

let barabaraAnime = function() {

    let _arr = [],
        _randArr = [],
        _rand,
        i = 0,
        _len = 0;

    for (i = 0; i < $("#l-kv.l-anime .object-line").length; i++) {
        _arr[i] = i + 1;
    }

    for(i=0,_len=_arr.length;i<6;i++,_len--){
        _rand = Math.floor( Math.random() * _len);
        _randArr.push(_arr[_rand]);
        _arr[_rand] = _arr[_len-1];
    }

    let _delayS = [ i * 80 / 100 ] * 1000;
    let _delayE = [ i * 100 / 100 ] * 1000;

    $("#l-kv.l-anime .object-line__1 .object.num"+_randArr[0]+",#l-kv.l-anime .object-line__2 .object.num"+_randArr[1]+",#l-kv.l-anime .object-line__3 .object.num"+_randArr[2]+",#l-kv.l-anime .object-line__4 .object.num"+_randArr[3]+"").delay(_delayS).queue(function() {
        $(this).addClass('up').dequeue();
    });

    $("#l-kv.l-anime .object-line__1 .object.num"+_randArr[0]+",#l-kv.l-anime .object-line__2 .object.num"+_randArr[1]+",#l-kv.l-anime .object-line__3 .object.num"+_randArr[2]+",#l-kv.l-anime .object-line__4 .object.num"+_randArr[3]+"").delay(_delayE).queue(function() {
        $(this).removeClass('up').dequeue();
    });

}

//============================================================

// -- resize

//============================================================

let resize = {

    init:function(){

        _screenWidth = $(window).width();
        _screenHeight = $(window).height();
        let $svgCover = $("#l-kv__mask");

        $svgCover.css({
            "width":_screenWidth * 2,
            "height":"auto"
        });

        $svgCover.css({
            "left":- (_screenWidth / 2)
        });
    }

};

$(document).on("ready",function () {
    if(!$('#top')[0]) return;
    //if($(".l-anime")[0]) handleEffectParent();
    //$('html,body').animate({ scrollTop: 0 }, '1');
});
$(window).on("load",function () {
    //$('html,body').animate({ scrollTop: 0 }, '1');
    if(!$('#top')[0]) return;
    _scr = $(window).scrollTop();
    resize.init();
    if($(".l-anime")[0]){
        //handleEffectParent();
        echo.init(); kv.init();
    }

    TweenMax.to($("#loader"), .68, {
        display: 'none',
        opacity:0,
        ease: Sine.easeOut
    });
});
$(window).on("resize",function () {
    if(!$('#top')[0]) return;
});

$(window).scroll(function () {
    if(!$('#top')[0]) return;
    _scr = $(window).scrollTop();
    clearInterval(_timer);
    if(_timeFlg){
        _timeFlg = false;
        if(_moveAnimeA){
            _moveAnimeA.pause();
        }
        if(_moveAnimeB){
            _moveAnimeB.pause();
        }
        setTimeout(function(){

            if(!$(".l-anime")[0] || _screenWidth < 750 || !_loadFlg) return false;

            if(_scr < _screenHeight / 1.2){
                _moveAnimeA.play();
                _moveAnimeB.play();
            }

            handleEffectParent();
            handleEffect();
            //_timer = setInterval(moveAnime, 1000 / 60); //☆☆☆☆☆
            _timer = setInterval(function(){
                barabaraAnime();//△△△△△
            },6e3);
            _timeFlg = true;
            return _timeFlg;

        }, 1750);
    }
});

function handleEffectParent() {

    if( _screenWidth < 750 ) return false;

    $target = $('#l-kv');

    // for (i = 0; i < $target.length; i++) {
    //     _t_height = $target[i].offsetHeight;
    //     _t_classN = $target[i].className;
    //     _offsetY = $($target[i]).offset().top;
    //     if (_offsetY <= _scr + _screenHeight && _scr <= (_offsetY + _t_height)) { // 画面内;
    //         if (_t_classN.indexOf('d-show') == -1) { // d-showなし
    //             $($target[i]).removeClass("d-none").addClass("d-show");
    //         }
    //     } else {
    //         if (_t_classN.indexOf('d-none') == -1) { // d-noneなし
    //             $($target[i]).removeClass("d-show").addClass("d-none");
    //         }
    //     }
    // }

    _t_height = $target.height() + ($('#l-header').height()*2);
    _t_classN = $target.attr('class');
    _offsetY = 0;
    if (_offsetY <= _scr + _screenHeight && _scr <= (_offsetY + _t_height)) { // 画面内;
        // if (_t_classN.indexOf('d-show') == -1) { // d-showなし
        //     $($target).removeClass("d-none").addClass("d-show");
        //     TweenMax.set($("#l-kv.l-anime").find(".object-matome"), {
        //         display:'block'
        //     });
        // }
        TweenMax.set($("#l-kv.l-anime").find(".object-matome"), {
            display:'block'
        });
    } else {
        // if (_t_classN.indexOf('d-none') == -1) { // d-noneなし
        //     $($target).removeClass("d-show").addClass("d-none");
        //     TweenMax.set($("#l-kv.l-anime").find(".object-matome"), {
        //         display:'none'
        //     });
        // }
        TweenMax.set($("#l-kv.l-anime").find(".object-matome"), {
            display:'none'
        });
    }
}
function handleEffect(){

    if(_screenWidth < 750) return false;
    $target = $("#l-kv.l-anime").find(".object");

    for (i = 0; i < $target.length; i++) {
        _t_width = $target[i].offsetWidth;
        _t_height = $target[i].offsetHeight;
        _t_classN = $target[i].className;
        _offsetX = $target[i].getBoundingClientRect().left;
        _offsetY = $target[i].getBoundingClientRect().top;
        if((_offsetX + 400) >= 0 && _offsetX <= _screenWidth && (_offsetY + 400) >= 0 && _offsetY <= _screenHeight){
            if(_t_classN.indexOf('d-show') == -1) { // d-showなし
                $($target[i]).removeClass("d-none").addClass("d-show");
            }
        }else{
            if(_t_classN.indexOf('d-none') == -1) { // d-noneなし
                $($target[i]).removeClass("d-show").addClass("d-none");
            }
        }
    }

}