import smoothScrollManager from '../module/smoothScrollManager';

let smoothScroll = new smoothScrollManager();

var information = {
    url    : '',
    count  : 0,
    List   : '',
    type   : '',
    init : function () {

        information.count = 0;
        information.url = location.href;

        $("#js__ajaxload").on("click",function(){

            information.count ++;

            $.when(
                $.ajax({
                    url: information.url,
                    cache : false,
                    data:{
                        more:true,
                        count:information.count
                    },
                    success:function(data){

                        information.list = $('#js__loadlist', $(data)).html();

                        $("#js__loadlist").append(information.list);

                        smoothScroll.resize();

                    }

                })
            ).done(function(data){
                for (var i = 0; i < $("#js__loadlist li").not('.is-done').length; i++) {
                    $("#js__loadlist li").not('.is-done').eq(i).delay(.2*i).queue(function() {
                        $(this).addClass('is-done').dequeue();
                    });
                }
                $("#js__ajaxload").html($('#js__ajaxload', $(data)).html());
            });
        });

    }
}


$(window).on("load",function () {
    if($("#information")[0]) information.init();
});