var footer = {
    $button : $("#l-fixed-button"),
    $fixed : $("#l-fixed-button__fixed"),
    posi   : 0,
    scr : 0,
    init : function () {
        footer.scr = $(window).scrollTop() + $(window).height();
        footer.posi = footer.$button.offset().top + 60;
        if(footer.scr >= footer.posi){
            footer.$fixed.css({'position':'relative'});
        }else{
            footer.$fixed.css({'position':'fixed'});
        }
    }
}


$(window).on("load scroll",function () {
    //if($("#l-fixed-button")[0]) footer.init();
});
