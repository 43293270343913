import Header from './module/header';
import Footer from './module/footer';
import Map from './module/map';
import Modal from './module/modal';
import Scroll from './module/scroll';
import Information from './view/information';
import Top from './view/top';

$(document).ready(function(){
    $('html,body').animate({ scrollTop: 0 }, '1');
});

$('#loader').height(window.innerHeight);

$(window).on('load',function(){

    //$('html,body').animate({ scrollTop: 0 }, '1');

    let Scroll = function(position){
        $('html,body').animate({scrollTop: position}, 300);
    }

    $(".js__alllink").on("click",function () {
        location.href = $(this).find('a').attr("href");
        return false;
    });

    $(".js__anchor").on("click",function () {
        let hash = $(this).attr("href");
        let position = $(hash).find('.js__scroll-title').offset().top - $('#l-header').height() - 20;
        Scroll(position);
        return false;
    });

    $("#page-top a").on("click",function () {
        let position = 0;
        Scroll(position);
        return false;
    });

    let hash = location.hash;
    if(hash !== '' && hash !== null && hash !== undefined){
        setTimeout(function() {
            TweenMax.to($("#loader"), .68, {
                display: 'none',
                opacity:0,
                ease: Sine.easeOut
            });
        },1e3);
    }else{
        TweenMax.to($("#loader"), .68, {
            display: 'none',
            opacity:0,
            ease: Sine.easeOut
        });
    }


});

let getTrans = function(t) {
    var s = window.getComputedStyle(t.get(0));
    var m = s.getPropertyValue("-webkit-transform") || s.getPropertyValue("-moz-transform") || s.getPropertyValue("-ms-transform") || s.getPropertyValue("-o-transform") || s.getPropertyValue("transform");

    if (m === 'none') {
        m = 'matrix(0,0,0,0,0)';
    }else{
        m = m;
    }

    var val = m.match(/([-+]?[\d\.]+)/g);
    return val[14] || val[5] || 0;
};