var header = {
    $header : $("#l-header"),
    $fixed : $("#l-header__fixed"),
    $trigger : $("#l-header__trigger"),
    $navi : $("#l-header__navi"),
    h      : 0,
    init : function () {
        header.$fixed.addClass("fixed");
        $('body,html').animate({scrollTop: 0},150);

        header.$trigger.on("click",function(){
            if(header.$navi.hasClass("open")){
                header.close();
            }else{
                header.open();
            }
        });
        header.$navi.find('a').on("click",function(){
            if($(window).width() < 750) header.close();
        });
    },

    open : function(){
        header.$trigger.addClass("open");
        header.$navi.addClass("open");
        TweenMax.set(header.$navi.find("li"), {x:'-30px'});
        TweenMax.to(header.$navi, 0.68, {
            opacity: 1,
            display:'block',
            x:'0px',
            ease: Back.easeOut.config(1.7),
        });
        TweenMax.staggerTo(header.$navi.find("li"), 0.4,
            {
                x:'0px',
                ease: Back.easeOut.config(1.7)
            },
            0.06
        );
    },

    close : function(){
        header.$trigger.removeClass("open");
        header.$navi.removeClass("open");
        TweenMax.to(header.$navi, 0.68, {
            opacity: 0,
            display:'none',
            x:'0px',
            ease: Back.easeOut.config(1.7),
            onComplete:function(){
                header.$navi.removeAttr("style");
            }
        });
    }

}


$(window).on("load",function () {
    header.init();
});
